import { reactive, toRefs, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
// import ScanPage from '@/components/scan.vue';
import EngineSelect from "@/components/engine-select.vue";
import { fetchHandler, setCookie, setAuthorization, loginJump, } from "@/utils";
import { loginByDingScan, loginByWxworkScan, loginByCorp } from "@/apis";
import useScan from "@/uses/use-scan";
import "@/styles/common.less";
export default {
    name: "LoginScan",
    components: {
        // ScanPage,
        EngineSelect,
    },
    setup() {
        const state = reactive({
            isCallback: true,
            engines: new Array(),
        });
        const params = useScan();
        const isAnnualDataMessage = computed(() => {
            return params.messagetype === "AnnualData";
        });
        const route = useRoute();
        if (route.query.fromPath === "templateCenter") {
            window.sessionStorage.setItem("origin_fromPath", route.query.fromPath);
            window.sessionStorage.setItem("templateCenter_redirect", route.query.redirectUrl);
        }
        // 登录指定企业（登录后切换企业场景）
        const loginByEngine = async (engineCode) => {
            const ret = await loginByCorp({ enginecode: engineCode });
            fetchHandler(ret, () => {
                const { token, shardKey } = ret.result;
                setAuthorization(token || "", shardKey || "");
                window.location.href = `${window.location.origin}/home.html?t=${new Date().getTime()}${engineCode ? `&enginecode=${engineCode}` : ``}`;
            });
        };
        // 扫码后回调
        const afterScan = async (code) => {
            let ret;
            const scanType = window.sessionStorage.getItem("scanType");
            const engineCode = window.sessionStorage.getItem("engineCode");
            if (scanType === "wxwork") {
                ret = await loginByWxworkScan({ code });
            }
            else {
                ret = await loginByDingScan({
                    code,
                    scanappid: "",
                    enginecode: engineCode || "",
                });
            }
            fetchHandler(ret, (ret) => {
                const { token, shardKey, havecorps, corps } = ret.result;
                setAuthorization(token || "", shardKey || "");
                console.log(ret.result);
                if (havecorps && corps && corps.length > 1) {
                    state.engines = corps;
                    // (state.engines as any).push({ Key: 'ljm239uafk6k5t41otue2ehb5', Value: 'ZL许可测试企业' });
                }
                else {
                    const flag = loginJump(token);
                    if (flag)
                        return;
                    window.location.replace(`${window.location.origin}/home.html?t=${new Date().getTime()}${engineCode ? `&enginecode=${engineCode}` : ``}`);
                }
            });
            if (engineCode) {
                setCookie("EngineCode", "", -1);
            }
        };
        const handler = (params) => {
            const { code, auth_code: authCode } = params;
            state.isCallback = !!code || !!authCode;
            if (code || authCode) {
                afterScan(code || authCode);
            }
            // todo 梳理各种入口场景
            // 1. 钉钉pc端浏览器打开
            // 2. 扫码回调
            // 3. 外链、公开查询
        };
        handler(params);
        onMounted(() => {
            if (!state.isCallback && !isAnnualDataMessage.value) {
                // 如果是不是回调或者消息进来，直接回到扫码页面，看上面注释 其实就是把这个页面的扫码功能移除出去，只保留一个扫码
                const { currentRoute, push } = useRouter();
                // push({
                //   name: "Login",
                //   query: {
                //     type: "ding",
                //   },
                // });
            }
        });
        return {
            ...toRefs(state),
            isAnnualDataMessage,
        };
    },
};
