import { loginByCorp } from "../apis";
import { getCookie, fetchHandler, setAuthorization, loginJump } from "../utils";
const LogoutRedirectUrlKey = "logoutRedirectUrl";
export default {
    name: "EngineSelect",
    props: {
        engines: {
            type: Array,
        },
    },
    setup() {
        const selectEngine = async (engine) => {
            const { Key } = engine;
            const logoutRedirectUrl = getCookie(LogoutRedirectUrlKey);
            const ret = await loginByCorp({ enginecode: Key });
            fetchHandler(ret, () => {
                // 重新存储cookie
                const { token, shardKey } = ret.result;
                setAuthorization(token || "", shardKey || "");
                const flag = loginJump(token);
                if (flag)
                    return;
                const nowTime = new Date().getTime();
                if (logoutRedirectUrl) {
                    // window.location.href = `${window.location.origin}${logoutRedirectUrl.replace('~', '')}&t=${nowTime}`;
                    window.location.replace(`${window.location.origin}${logoutRedirectUrl.replace("~", "")}&t=${nowTime}`);
                }
                else {
                    // window.location.href = `${window.location.origin}/home.html?t=${nowTime}${Key ? `&enginecode=${Key}` : ``}`;
                    window.location.replace(`${window.location.origin}/home.html?t=${nowTime}${Key ? `&enginecode=${Key}` : ``}`);
                }
            });
        };
        return {
            selectEngine,
        };
    },
};
